import { LDEvaluationDetail } from 'launchdarkly-node-server-sdk';
import { SEOSegmentationPageType } from '@/__generated__/globalTypes';
import {
  getCaregiver_getCaregiver_profiles_childCareCaregiverProfile as ChildCareCaregiverProfileType,
  getCaregiver_getCaregiver_profiles_petCareCaregiverProfile as PetCareCaregiverProfileType,
  getCaregiver_getCaregiver_profiles_seniorCareCaregiverProfile as SeniorCareProfileType,
  getCaregiver_getCaregiver_profiles_tutoringCaregiverProfile as TutoringProfileType,
  getCaregiver_getCaregiver_profiles_houseKeepingCaregiverProfile as HousekeepingProfileType,
} from '@/__generated__/getCaregiver';
import { seoSegmentationListingPage_seoSegmentationDetails_SEOSegmentationDetailsSuccess_segmentationDetails_seoPageMetadata as SEOSegmentationPageMetadataType } from '@/__generated__/seoSegmentationListingPage';
import { type SvgIconProps, type SxProps, type Theme } from '@mui/material';
import { type IconProps } from '@care/react-icons';

export interface ErrorData {
  errorMsg: string;
  path?: string;
  payload?: { [key: string]: any };
  serverError?: { [key: string]: any };
  [key: string]: any;
}

export interface InfoData {
  infoMsg: string;
  payload?: { [key: string]: any };
  [key: string]: any;
}

export enum PageTemplate {
  CAREGIVER_DIRECTORY = 'CAREGIVER_DIRECTORY', // segmentation list page
  JOB_DIRECTORY = 'JOB_DIRECTORY',
  COST_GUIDE = 'COST_GUIDE',
  PROVIDER_PROFILE = 'PROVIDER_PROFILE',
  JOB_PROFILE = 'JOB_PROFILE',
  ALL_SERVICES_DIRECTORY = 'ALL_SERVICES_DIRECTORY',
  ALL_STATES_DIRECTORY = 'ALL_STATES_DIRECTORY',
}

export interface LDTargetAttributes {
  pageType?: SEOSegmentationPageType;
  serviceIdForMember?: string;
  serviceId?: string;
  segmentationSubService?: string | null;
  seoSegmentId: string;
  deviceChar: any;
  searchingFor?: string;
  serviceShortName?: string | string[];
  pageTemplate?: PageTemplate;
}

export interface CommonPageAttributes {
  avgPayRate?: string | null;
  deviceChar?: any;
  userGeoInfo?: any;
  segmentationSubService?: any;
  seoSegmentId: string;
  serviceId: string;
  serviceIdForMember: string;
  segmentNamePlural: string;
  segmentNamePluralLowerCase: string;
  segmentNameSingular: string;
  segmentNameSingularLowerCase: string;
  pageType?: string;
  city?: string | null;
  state?: string | null;
  totalNumberOfResults?: string | null;
  avgReviewRating?: string | null;
  searchingFor?: string | null;
  seoPageId?: SEOSegmentationPageMetadataType['seoPageId'];
  pageTemplate?: PageTemplate | null;
}
export interface TypeAheadOptions {
  label: string;
  value: string;
}

export type FeatureFlags = Record<string, LDEvaluationDetail>;
export type SxClassProps = Record<string, SxProps<Theme>>;

export type ActiveProfile =
  | ChildCareCaregiverProfileType
  | PetCareCaregiverProfileType
  | SeniorCareProfileType
  | TutoringProfileType
  | HousekeepingProfileType;

export interface HeroImages {
  heroImageMobileURL: string;
  heroImageTabletURL: string;
  heroImageDesktopURL: string;
}

// all string values in GAMTargetingRules should default to 'none' if not provided
export type GAMTargetingRules = {
  vertical: 'cc' | 'pc' | 'sc' | 'tu' | 'hk' | 'sn' | 'cg' | 'none';
  // mType doesn't apply to our non-member pages
  mType: 'none';
  mID: string;
  mLevel: boolean;
  contType: 'seeker' | 'provider' | 'none';
};
export type IconComponent = (props: IconProps) => React.FunctionComponentElement<SvgIconProps>;
