import { LDTargetAttributes, PageTemplate } from './types/common';
import {
  SEOSegmentationSearchFilters as Filters,
  SEOSegmentationJobTypeFilter,
  JobType,
  SEOSegmentationSearchingFor,
  ServiceType,
  ServiceIdForMember as ServiceIdForMemberEnumType,
  CostGuidePercentageRelativePosition,
  UsStateCode,
  ReviewInfoCareType,
  ChildCareAgeGroups,
  ActivityParticipationType,
  DayOfWeek,
} from './__generated__/globalTypes';

export const CARE_DEVICE_ID_COOKIE_KEY = 'care_did';

export const COMMON_SEO_ENROLLMENT_PARAMS = {
  fromSeoPage: true,
  source: 'SEOSegmentation',
};

export enum ServiceIdForMember {
  ACADEMIC = 'academic',
  ART = 'art',
  BABYSITTER = 'babysitter',
  CAMPS_LOCATION = 'campsLocation',
  CARE_GIGS = 'careGigs',
  CARE_GIGS_JOB = 'careGigsJob',
  CHILD_CARE = 'childCare',
  CHILD_CARE_AFTER_SCHOOL_CARE = 'childCareAfterSchoolCare',
  CHILD_CARE_JOB = 'childCareJob',
  CHILD_CARE_LOCATION = 'childCareLocation',
  DANCE = 'dance',
  FAMILY_CHILD_CARE = 'family_CHILDCARE',
  HOME_CARE_LOCATION = 'homeCareLocation',
  HOUSEKEEPING = 'housekeeping',
  HOUSEKEEPING_JOB = 'housekeepingJob',
  HOUSING_LOCATION = 'housingLocation',
  MUSIC = 'music',
  NANNY = 'nanny',
  NANNY_SHARE = 'nannyShare',
  OUTDOOR_AND_ADVENTURE = 'outdoorAndAdventure',
  PET_CARE = 'petCare',
  PET_CARE_JOB = 'petCareJob',
  PET_DAY_CARE_LOCATION = 'petDayCareLocation',
  PET_SITTING_WALKING = 'petSittingWalking',
  SENIOR_CARE = 'seniorCare',
  SENIOR_CARE_JOB = 'seniorCareJob',
  SPECIAL_NEEDS = 'specialNeeds',
  SPECIAL_NEEDS_JOB = 'specialNeedsJob',
  SPORTS = 'sports',
  STEM = 'stem',
  SUMMER_CHILD_CARE_JOB = 'summerChildCareJob',
  TUTORING = 'tutoring',
  TUTORING_AFTER_SCHOOL_CARE = 'tutoringAfterSchoolCare',
  TUTORING_JOB = 'tutoringJob',
}

export enum ServiceId {
  CHILDCARE = 'CHILDCARE',
  SENIRCARE = 'SENIRCARE',
  HOUSEKEEP = 'HOUSEKEEP',
  TUTORINGX = 'TUTORINGX',
  PETCAREXX = 'PETCAREXX',
  CAREGIGSX = 'CAREGIGSX',
  SPCLNEEDS = 'SPCLNEEDS',
}

export const UI_FEATURE_FLAGS = Object.freeze({
  DAYCARE_DIRECTORY_FIRST_FLOW: {
    id: 'daycare-directory-first-flow',
    condition: () => {
      return true;
    },
  },
  SEO_3515_PILLAR_PAGES_ROUTING: {
    id: 'seo-3515-pillar-pages-routing',
    condition: () => {
      return true;
    },
  },
  SEO_TEST_FLAG: {
    id: 'seo-mfe-ui-test-flag',
    condition: (ldTargetAttributes: LDTargetAttributes) =>
      ldTargetAttributes?.seoSegmentId === '/babysitters',
  },
  SEO_1265_PROVIDER_PROFILE_PERSONALIZED_CTA: {
    id: 'seo-1265-provider-profile-personalized-cta',
    condition: () => {
      return true;
    },
  },
  COST_GUIDE_SPECIALTY: {
    id: 'cost-guide-specialty',
    condition: () => {
      return true;
    },
  },
  SEO_1586_A_B_TEST_HOT_JAR_EVENTS_TEST: {
    id: 'seo-1586-a-b-test-hot-jar-events-test',
    condition: () => {
      return true;
    },
  },
  SEO_3193_ABOVE_THE_FOLD_CONVERSION_TEST: {
    id: 'seo-3193-above-the-fold-conversion-test',
    condition: (ldTargetAttributes: LDTargetAttributes) => {
      return (
        ldTargetAttributes?.searchingFor === SEOSegmentationSearchingFor.PROVIDER ||
        ldTargetAttributes?.searchingFor === SEOSegmentationSearchingFor.ALL_PROVIDER
      );
    },
  },
  SEO_3126_DAYCARE_ROBUST_CARDS: {
    id: 'seo-3126-daycare-robust-cards',
    condition: (ldTargetAttributes: LDTargetAttributes) => {
      return ldTargetAttributes?.serviceIdForMember === ServiceIdForMember.CHILD_CARE_LOCATION;
    },
  },
  SEO_3169_SALC_ROBUST_PROVIDER_CARDS: {
    id: 'seo-3169-salc-robust-provider-cards',
    condition: (ldTargetAttributes: LDTargetAttributes) => {
      return ldTargetAttributes?.serviceIdForMember === ServiceIdForMember.HOUSING_LOCATION;
    },
  },
  SEO_3217_ABOVE_THE_FOLD_CONVERSION_TEST_PROVIDERS: {
    id: 'seo-3217-above-the-fold-conversion-test-providers',
    condition: (ldTargetAttributes: LDTargetAttributes) => {
      return (
        ldTargetAttributes?.searchingFor === SEOSegmentationSearchingFor.SEEKER_JOB ||
        ldTargetAttributes?.searchingFor === SEOSegmentationSearchingFor.ALL_JOB
      );
    },
  },
  SEO_3487_GAM_ADS_SEO_MFE: {
    id: 'seo-3487-gam-ads-seo-mfe',
    condition: (ldTargetAttributes: LDTargetAttributes) => {
      return (
        ldTargetAttributes?.pageTemplate === PageTemplate.CAREGIVER_DIRECTORY ||
        ldTargetAttributes?.pageTemplate === PageTemplate.JOB_DIRECTORY ||
        ldTargetAttributes?.pageTemplate === PageTemplate.COST_GUIDE ||
        ldTargetAttributes?.pageTemplate === PageTemplate.PROVIDER_PROFILE ||
        ldTargetAttributes?.pageTemplate === PageTemplate.JOB_PROFILE
      );
    },
  },
  OOH_SKIP_ENROLLMENT_STEP_FOR_SEO_SC_SMB_PROFILE: {
    id: 'ooh-skip-enrollment-step-for-seo-sc-smb-profile',
    condition: (ldTargetAttributes: LDTargetAttributes) => {
      return (
        ldTargetAttributes?.serviceId === ServiceId.SENIRCARE &&
        ldTargetAttributes?.serviceIdForMember === ServiceIdForMember.HOUSING_LOCATION
      );
    },
  },
} as const);

export const SERVER_FEATURE_FLAGS = Object.freeze({
  GTM_ENABLED: 'gtm-enabled',
  SENTRY_TRACE_TRANSACTION: 'sentry-trace-transaction',
  SENTRY_TRACE_TRANSACTION_SAMPLING_RATES: 'sentry-trace-transaction-sampling-rates',
  GTM_JS_URL: 'gtm-js-url',
  TTP_FAVICON_URL: 'ttp-favicon-url',
  COST_GUIDE_LOCAL_PAGE: 'seo-1331-cost-guide-local-page',
  ACCESSIBE_ENABLED: 'accessibe-enabled',
  SEO_MFE_LOG_AKAMAI_HEADER: 'seo-mfe-log-akamai-header',
} as const);

export const SEO_SERVICE_DETAILS: {
  [key: string]: { pageSource: string; shortName: string; serviceIdForMemberVal: string };
} = {
  CHILDCARE: {
    pageSource: 'child-care',
    shortName: 'cc',
    serviceIdForMemberVal: 'childCare',
  },
  SENIRCARE: {
    pageSource: 'senior-care',
    shortName: 'sc',
    serviceIdForMemberVal: 'seniorCare',
  },
  HOUSEKEEP: {
    pageSource: 'housekeeping',
    shortName: 'hk',
    serviceIdForMemberVal: 'housekeeping',
  },
  TUTORINGX: {
    pageSource: 'tutoring',
    shortName: 'tu',
    serviceIdForMemberVal: 'tutoring',
  },
  PETCAREXX: {
    pageSource: 'pet-care',
    shortName: 'pc',
    serviceIdForMemberVal: 'petCare',
  },
  CAREGIGSX: {
    pageSource: 'caregigs',
    shortName: 'cg',
    serviceIdForMemberVal: 'careGigs',
  },
  SPCLNEEDS: {
    pageSource: 'special-needs',
    shortName: 'sn',
    serviceIdForMemberVal: 'specialNeeds',
  },
};

export const SEO_FILTERS_ORDER: { [key: string]: Filters[] } = {
  babysitter: [
    Filters.DISTANCE,
    Filters.PAY_RATE,
    Filters.AVAILABILITY,
    Filters.YEARS_OF_EXPERIENCE,
  ],
  campsLocation: [Filters.DISTANCE],
  careGigs: [Filters.DISTANCE, Filters.SERVICES, Filters.PAY_RATE],
  childCareLocation: [Filters.AGE, Filters.DISTANCE, Filters.SCHEDULE, Filters.HOURS],
  housekeeping: [
    Filters.DISTANCE,
    Filters.SERVICES,
    Filters.PAY_RATE,
    Filters.YEARS_OF_EXPERIENCE,
    Filters.AVAILABILITY,
  ],
  petCare: [Filters.DISTANCE, Filters.SERVICES, Filters.PAY_RATE, Filters.YEARS_OF_EXPERIENCE],
  seniorCare: [
    Filters.DISTANCE,
    Filters.SERVICES,
    Filters.PAY_RATE,
    Filters.AVAILABILITY,
    Filters.YEARS_OF_EXPERIENCE,
  ],
  seniorCareJob: [Filters.DISTANCE, Filters.JOB_TYPE, Filters.PAY_RATE],
  specialNeeds: [
    Filters.DISTANCE,
    Filters.SERVICES,
    Filters.PAY_RATE,
    Filters.EXPERIENCE_WITH_AGES,
  ],
  tutoring: [Filters.DISTANCE, Filters.SUBJECT_AREA, Filters.PAY_RATE, Filters.YEARS_OF_EXPERIENCE],
  get childCare() {
    return this.babysitter;
  },
  get childCareAfterSchoolCare() {
    return this.babysitter;
  },
  get familyCareLocation() {
    return this.childCareLocation;
  },
  get homeCareLocation() {
    return this.campsLocation;
  },
  get housingLocation() {
    return this.campsLocation;
  },
  get nanny() {
    return this.babysitter;
  },
  get nannyLocation() {
    return this.campsLocation;
  },
  get petDayCareLocation() {
    return this.campsLocation;
  },
  get tutoringAfterSchoolCare() {
    return this.tutoring;
  },
};

export const RATE_DURATION_ABBREVIATIONS: { [key: string]: string } = {
  DAILY: 'day',
  HOURLY: 'hr',
  MONTHLY: 'mo',
  WEEKLY: 'wk',
  FLAT: 'flat',
  NIGHTLY: 'night',
  PER_SESSION: 'session',
};

export const JOB_TYPE_FILTER_OPTIONS: { [key in SEOSegmentationJobTypeFilter]: string } = {
  ANY: 'Any',
  FULL_TIME: 'Full time',
  ONE_TIME: 'One time',
  PART_TIME: 'Part time',
};

export const JOB_TYPES: { [key in JobType]: string | undefined } = {
  FULL_TIME: 'Full time',
  ONE_TIME: 'One time',
  PART_TIME: 'Part time',
  BACKUP_CARE: undefined,
  ONE_TIME_CARE: undefined,
  IB_RECURRING_CARE: 'Recuring care',
};

export const SEARCH_RESULT_TYPE = {
  PROVIDER: 'PROVIDER',
  JOB: 'JOB',
  CAREGIVER: 'CAREGIVER',
} as const;

export const JOB_SEARCHING_FOR: { [key in SEOSegmentationSearchingFor]?: string } = {
  SMB_JOB: 'SMB_JOB',
  SEEKER_JOB: 'SEEKER_JOB',
  ALL_JOB: 'ALL_JOB',
};

export const CONVERSION_CTA_LABELS = [
  {
    label: 'Right Now',
    intent: 'rightNow',
  },
  {
    label: 'Within a week',
    intent: 'withInWeek',
  },
  {
    label: 'In 1-2 months',
    intent: 'withInMonth',
  },
  {
    label: 'Just browsing',
    intent: 'justBrowsing',
  },
];

// temporary until we return serviceId for Profiles
export const SEO_SERVICE_SHORT_NAMES: { [key: string]: string } = {
  cc: `CHILDCARE`,
  sc: `SENIRCARE`,
  hk: `HOUSEKEEP`,
  tu: `TUTORINGX`,
  pc: `PETCAREXX`,
  cg: `CAREGIGSX`,
  sn: `SPCLNEEDS`,
};

export const SERVICE_ID_FOR_MEMBER: { [key: string]: string } = {
  babysitter: 'babysitter',
  childCare: 'childCare',
  homeCareLocation: 'homeCareLocation',
  housekeeping: 'housekeeping',
  housingLocation: 'housingLocation',
  nanny: 'nanny',
  specialNeeds: 'specialNeeds',
};

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export const WINDOW_SENTRY_TRACE_TRANSACTION_KEY = 'SENTRY_TRACE_TRANSACTION';
export const WINDOW_SENTRY_TRACE_TRANSACTION_SAMPLING_RATE_KEY =
  'SENTRY_TRACE_TRANSACTION_SAMPLING_RATE';

// This will be removed since more exports are coming from master
/* eslint-disable */
export const CLIENT_SIDE_ERROR_TAG = 'ClientSideError';

// store values as lowercase strings with the exception of acronyms
export const CAREGIVER_SERVICES: Record<string, string> = {
  administersMedicine: 'administration of medicine',
  atticCleaning: 'attic cleaning',
  basementCleaning: 'basement cleaning',
  bathing: 'bathing',
  bathroomCleaning: 'bathroom cleaning',
  boardsOvernight: 'boarding',
  cabinetCleaning: 'cabinet cleaning',
  carpetCleaning: 'carpet cleaning',
  carpooling: 'carpooling',
  changingBedLinens: 'changing bed linens',
  companionship: 'companionship',
  craftAssistance: 'craft assistance',
  dishes: 'dishes',
  dementia: 'dementia',
  doesAmbulation: 'ambulation',
  doesAppliedBehaviorAnalysis: 'applied behavior analysis',
  doesBathing: 'bathing/grooming/hygiene',
  doesBedBaths: 'bed baths',
  doesBladderManagementAssistance: 'bladder management assistance',
  doesBloodSugarTesting: 'blood sugar testing',
  doesBowelManagementAssistance: 'bowel management assistance',
  doesBrushingProtocol: 'brushing protocol',
  doesDailyFeeding: 'daily feeding',
  doesDressingAssistance: 'dressing assistance',
  doesEpiPenInjections: 'epi pen injections',
  doesErrands: 'errands/shopping',
  doesFeeding: 'feeding',
  doesHousecleaning: 'house cleaning',
  doesHouseSitting: 'house sitting',
  doesInsulinInjections: 'insulin injections',
  doesLaundry: 'laundry',
  doesLongDistanceTransportation: 'long distance transportation',
  doesMealPreparation: 'meal preparation',
  doesMedicalTransportation: 'medical transportation',
  doesPetSitting: 'pet sitting',
  doesPetWalking: 'pet walking',
  doesPositiveBehavioralSupport: 'positive behavioral support',
  doesRepositioning: 'repositioning',
  doesRespiratoryCare: 'respiratory care',
  doesSeizureAttendance: 'seizure attendance',
  doesStimmingAttendance: 'stimming attendance',
  doesTransferring: 'transferring/body lifting',
  dusting: 'dusting',
  errands: 'errands',
  feeding: 'feeding',
  furnitureTreatment: 'furniture treatment',
  generalRoomCleaning: 'general room cleaning',
  greenCleaning: 'green cleaning',
  groceryShopping: 'grocery shopping',
  groomsAnimals: 'grooming',
  heavyLifting: 'heavy lifting',
  helpStayingPhysicallyActive: 'help staying physically active',
  homeHealth: 'home health',
  homeModification: 'home modification',
  hospiceServices: 'hospice services',
  houseSitting: 'house sitting',
  kitchenCleaning: 'kitchen cleaning',
  laundry: 'laundry',
  laundryAssistance: 'laundry',
  lightHousekeeping: 'light cleaning',
  liveInHomeCare: 'live-in home care',
  mealPreparation: 'meal prep',
  medicalManagement: 'medical management',
  medicalTransportation: 'medical transportation',
  mobilityAssistance: 'mobility assistance',
  moveOutCleaning: 'move-out cleaning',
  organization: 'organization',
  ovenCleaning: 'oven cleaning',
  packingUnpacking: 'packing and unpacking',
  personalCare: 'personal care',
  petWasteCleanup: 'pet waste cleanup',
  plantCare: 'plant care',
  refrigeratorCleaning: 'refrigerator cleaning',
  respiteCare: 'respite care',
  retrievesMail: 'mail/newspaper retrieval',
  speaksSignLanguage: 'sign language',
  specializedCare: 'specialized care',
  specialNeeds: 'special needs',
  springSummerCleaning: 'spring and summer cleaning',
  surfacePolishing: 'surface polishing',
  swimmingSupervision: 'swimming supervision',
  trainsDogs: 'dog training',
  transportation: 'transportation',
  transportsPets: 'pet transportation',
  travel: 'travel',
  tutorsInCenter: 'tutoring at center for learning',
  tutorsInStudentsHome: "tutoring in student's home",
  tutorsInTeachersHome: "tutoring in seachers's home",
  tutorsOnline: 'online tutoring',
  vacuumingOrMopping: 'vacuuming and/or mopping',
  visitingPhysician: 'visiting physician',
  visitingNurse: 'visiting nurse',
  wallWashing: 'wall washing',
  watersPlants: 'water plants',
  windowWashing: 'window washing',
  worksWithAssistiveTechnology: 'assistive technology/AAC',
  worksWithGTubes: 'g tubes',
};

export const CAREGIVER_QUALITIES: { [key: string]: string } = {
  americanTutoringAssociationCertified: 'American Tutoring Association Certified',
  cprTrained: 'CPR / First Aid trained',
  comfortableWithPets: 'Comfortable with pets',
  doesNotSmoke: 'Non-smoker',
  hospiceExperience: 'Hospice experience',
  ownTransportation: 'Has a reliable car',
  alzheimersOrDementiaExperience: 'Alzheimers or dementia experience',
  certifiedNursingAssistant: 'Certified nursing assistant',
  homeHealthAideExperience: 'Home health aide experience',
  registeredNurse: 'Registered nurse',
  certifiedRegisterNurse: 'Registered nurse',
  certifiedTeacher: 'Certified teacher',
  childDevelopmentAssociate: 'Child development associate',
  doula: 'Doula',
  earlyChildDevelopmentCoursework: 'Early child development knowledge',
  crn: 'Registered nurse',
  earlyChildhoodEducation: 'Early childhood eductation',
  firstAidTraining: 'CPR / First Aid trained',
  nafccCertified: 'NAFCC Certified',
  specialNeedsCare: 'Special needs care',
  trustlineCertifiedCalifornia: 'Trustline Certified California',
  providesEquipment: 'Provides equipment',
  providesSupplies: 'Provides supplies',
  helpsWithArt: 'Art',
  helpsWithBusiness: 'Business',
  helpsWithComputers: 'Computers',
  helpsWithDance: 'Dance',
  helpsWithEnglish: 'English',
  helpsWithForeignLanguage: 'Foreign Language',
  helpsWithMath: 'Math',
  helpsWithMusicAndDrama: 'Music and Drama',
  helpsWithMusicalInstruments: 'Musical Instruments',
  helpsWithScience: 'Science',
  helpsWithSpecialEducation: 'Special Education',
  helpsWithSportsAndFitness: 'Sports and Fitness',
  helpsWithTestPrep: 'Test Prep',
  isComfortableWithPets: 'Comfortable with pets',
  ownsTransportation: 'Has a reliable car',
  caresForAmphibians: 'Amphibians',
  caresForBirds: 'Birds',
  caresForCats: 'Cats',
  caresForDogs: 'Dogs',
  caresForExoticPets: 'Exotic Pets',
  caresForFarmAnimals: 'Farm Animals',
  caresForFish: 'Fish',
  caresForHorses: 'Horses',
  caresForMammals: 'Mammals',
  caresForOtherPets: 'Other Pets',
  isBondedAndInsured: 'Bonded and Insured',
  isNappsCertified: 'NAPPS Certified',
  isRedCrossPetFirstAidCertified: 'Red Cross Pet First Aid Certified',
  deliversCarsForService: 'Deliver cars to mechanic',
  doesHouseSitting: 'House sitting',
  doesTransportation: 'Provides transportation',
  dropsOffLaundry: 'Dry cleaning & laundry drop off',
  dropsOffPackages: 'Post office drop off',
  feedsFish: 'Feed fish',
  handlesEventTickets: 'Handle event tickets',
  handlesInvitations: 'Handle mail invitations',
  helpsAtParties: 'Party helper: server, setup, etc.',
  helpsDuringInjury: 'Injury recovery: home help, general errands',
  helpsPackOrUnpack: 'Packing and unpacking',
  plansParties: 'Party planning',
  retrievesMail: 'Mail/Newspaper retrieval',
  takesOutTrash: 'Take out trash',
  waitsForHomeServices: 'Wait for cable or home services',
  watersPlants: 'Water plants',
  willGiftShopOrWrap: 'Gift shopping & wrapping',
  willGroceryShop: 'Grocery shopping',
  willReturnPurchases: 'Returns & exchanges',
  willScheduleMovers: 'Schedule moving company',
  caresForADD: 'ADD',
  caresForADHD: 'ADHD',
  caresForAdults: 'Adult (18-64 years)',
  caresForAspergers: 'Aspergers',
  caresForAsthma: 'Asthma',
  caresForAutism: 'Autism',
  caresForAutismSpectrumDisorder: 'Autism Spectrum Disorder',
  caresForBlindness: 'Blindness/visual impairment',
  caresForCancer: 'Cancer',
  caresForCeliac: 'Celiac',
  caresForCentralAuditoryProcessingDisorder: 'Central Auditory Processing Disorder',
  caresForCerebralPalsy: 'Cerebral Palsy',
  caresForCognitiveDisabilities: 'Cognitive disabilities',
  caresForCysticFibrosis: 'Cystic Fibrosis',
  caresForDeafness: 'Deafness',
  caresForDiabetes: 'Diabetes',
  caresForDwarfism: 'Dwarfism',
  caresForDyslexia: 'Dyslexia',
  caresForDevelopmentalDelays: 'Developmental delays',
  caresForDownSyndrome: 'Down syndrome',
  caresForEpilepsy: 'Epilepsy',
  caresForFetalAlcoholSyndrome: 'Fetal Alcohol Syndrome',
  caresForFoodAllergies: 'Food allergies',
  caresForFragileX: 'Fragile X',
  caresForHeartDefects: 'Heart defects',
  caresForHydrocephaly: 'Hydrocephaly',
  caresForInfants: 'Infant (up to 12 months)',
  caresForMentalIllness: 'Mental illness',
  caresForMobilityChallenges: 'Mobility challenges',
  caresForMultipleSclerosis: 'Mulitple Sclerosis',
  caresForMuscularDystrophy: 'Muscular dystrophy',
  caresForObesity: 'Obesity',
  caresForOtherNeeds: 'Other needs',
  caresForPervasiveDevelopmentalDisorder: 'Pervasive Developmental Disorder',
  caresForPolymicrogyria: 'Polymicrogyria',
  caresForPraderWilli: 'Prader Willi',
  caresForRettSyndrome: 'Rett Syndrome',
  caresForSeizureDisorder: 'Seizure Disorder',
  caresForSeniors: 'Senior (65+)',
  caresForSensoryIntegrationDisorder: 'Sensory Integration Disorder',
  caresForSickKids: 'Willing to care for sick kids',
  caresForSpeechDelay: 'Speech delay',
  caresForSpinalCordInjury: 'Spinal cord injury',
  caresForTeens: 'Teen (12-17 years)',
  caresForThyroidCondition: 'Thyroid Condition',
  caresForTouretteSyndrome: 'Tourette Syndrome',
  caresForYouth: 'Youth (1 to 11 years)',
  hasCprTraining: 'CPR training',
  hasFirstAidTraining: 'First Aid training',
  hasSpecialEducationCredentials: 'Special education credentials',
  isCertifiedNursingAssistant: 'Certified Nursing Assistant',
  isEarlyChildhoodEducator: 'Early childhood educator',
  isLicensedPracticingNurse: 'Licensed Practicing Nurse',
  isLicensedVocationalNurse: 'Licensed Vocational Nurse',
  isOccupationalTherapist: 'Occupational therapist',
  isPhysicalTherapist: 'Physical therapist',
  isRegisteredNurse: 'Registered Nurse',
  isSpeechTherapist: 'Speech therapist',
  isPSAMemberAndIsInsured: 'PSA member and insured',
  licensedNurse: 'Licensed Nurse',
  medicalEquipmentExperience: 'Medical Equipment Experience',
  woundCare: 'Wound Care',
};

export const MERCHANDIZED_JOB_INTERESTS: { [key: string]: any } = {
  companionCare: 'companion care',
  dateNight: 'child care',
  lightCleaning: 'light cleaning',
  mealPrepLaundry: 'meal prep',
  mover: 'packing/moving',
  personalAssistant: 'organizing/laundry',
  petHelp: 'help with pets',
  shopping: 'groceries/errands',
  transportation: 'transportation',
};

export const JOB_PREFERENCES: { [key: string]: string } = {
  isComfortableWithPets: 'Comfortable With Pets',
  doesNotSmoke: 'Non-Smoker',
  ownsTransportation: 'Has Own Transportation',
  assistsMobility: 'Mobility Assistance',
  caresForWounds: 'Wound Care',
  doesFeeding: 'Feeding',
  doesBathing: 'Bathing / Dressing',
  doesCompanionship: 'Companionship',
  doesHeavyLifting: 'Heavy lifting',
  doesLightHousekeeping: 'Light Housekeeping',
  helpsStayingActive: 'Helps Staying Physically Active',
  prepsMeals: 'Meal Preparation',
  promptsMedication: 'Medication Prompting',
  runsErrands: 'Errands / Shopping',
  transports: 'Transportation Required',
  doesHomeworkHelp: 'Homework Help',
  doesLaundry: 'Laundry',
  dropPickupChildren: 'Drop Pickup Children',
  doeOvernightCare: 'Overnight Care',
  doesBoarding: 'Boarding',
  doesGrooming: 'Grooming',
  doesMedicineAdministration: 'Medicine Administration',
  doesPlayAndExercise: 'Play & Exercise',
  doesTraining: 'Training',
  doesWalking: 'Walking',
  doesWasteCleanup: 'Waste Cleanup',
  providesEquipment: 'Provides Equipment',
  providesSupplies: 'Provides Supplies',
  educationLevel: 'Has attended college',
};

// Search filter START

export const EXPERIENCES = [
  { label: 'Any', value: '' },
  { label: '3+', value: '3' },
  { label: '5+', value: '5' },
  { label: '7+', value: '7' },
  { label: '10+', value: '10' },
];

export const EXPERIENCE_WITH_AGES_LIST = [
  { label: 'Infant (up to 12 months)', value: 'SNA001' },
  { label: 'Youth (1 to 11 years)', value: 'SNA003' },
  { label: 'Teen (12-17 years)', value: 'SNA005' },
  { label: 'Adult (18-64 years)', value: 'SNA002' },
  { label: 'Senior (65+)', value: 'SNA004' },
];

export const SERVICES_LIST_SENIOR_CARE = [
  { label: 'Assisted Living', value: 'SCS002' },
  { label: 'Senior Transportation', value: 'SCS003' },
  { label: 'Home Care', value: 'SCS004' },
  { label: 'Nursing Home Care', value: 'SCS005' },
  { label: 'Adult Day Health', value: 'SCS007' },
  { label: 'Independent Living', value: 'SCS008' },
];

export const SERVICES_LIST_HOUSEKEEPING = [
  { label: 'Bathroom Cleaning', value: 'HKS001' },
  { label: 'Kitchen Cleaning', value: 'HKS002' },
  { label: 'Bed Changing', value: 'HKS004' },
  { label: 'Oven Cleaning', value: 'HKS005' },
  { label: 'Refrigerator Cleaning', value: 'HKS006' },
  { label: 'Cabinet Cleaning', value: 'HKS007' },
  { label: 'Dishes', value: 'HKS008' },
  { label: 'Window Washing', value: 'HKS009' },
  { label: 'Laundry', value: 'HKS014' },
  { label: 'House Sitting', value: 'HKS020' },
];

export const SERVICES_LIST_TUTORING = [
  { label: 'Math', value: 'GES007' },
  { label: 'English', value: 'GES005' },
  { label: 'Science', value: 'GES008' },
  { label: 'Test Prep', value: 'GES011' },
  { label: 'Foreign Language', value: 'GES006' },
  { label: 'Computers', value: 'GES003' },
  { label: 'Art', value: 'GES012' },
  { label: 'Music & Drama', value: 'GES017' },
  { label: 'Musical Instruments', value: 'GES016' },
  { label: 'Dance', value: 'GES014' },
  { label: 'Sports & Fitness', value: 'GES018' },
  { label: 'Business', value: 'GES002' },
  { label: 'Special Education', value: 'GES010' },
];

export const SERVICES_LIST_PET_CARE = [
  { label: 'Pet Sitting', value: 'PCS010' },
  { label: 'Pet Walking', value: 'PCS001' },
  { label: 'Pet Day Care', value: 'PCS003' },
  { label: 'Boarding/Kenneling', value: 'PCS006' },
  { label: 'Dog Training', value: 'PCS007' },
  { label: 'Grooming', value: 'PCS008' },
];

export const SERVICES_LIST_CARE_GIGS = [
  { label: 'General Errands', value: 'CGS001' },
  { label: 'Shopping', value: 'CGS002' },
  { label: 'Vacation Services', value: 'CGS003' },
  { label: 'Parties & Events', value: 'CGS004' },
  { label: 'Moving Services', value: 'CGS005' },
];

export const SERVICES_LIST_SPECIAL_NEEDS = [
  { label: 'Ambulation', value: 'SNC001' },
  { label: 'Assistive Technology/AAC', value: 'SNC004' },
  { label: 'Bathing/Grooming/Hygiene', value: 'SNC007' },
  { label: 'Bed Baths', value: 'SNC010' },
  { label: 'Applied Behavior Analysis', value: 'SNC013' },
  { label: 'Positive Behavioral Support', value: 'SNC016' },
  { label: 'Blood Sugar Testing', value: 'SNC002' },
  { label: 'Body Lifting', value: 'SNC005' },
  { label: 'Brushing Protocol', value: 'SNC008' },
  { label: 'EPI Pen Injection', value: 'SNC011' },
  { label: 'Feeding', value: 'SNC014' },
  { label: 'G Tubes', value: 'SNC017' },
  { label: 'Insulin Injections', value: 'SNC003' },
  { label: 'Repositioning', value: 'SNC006' },
  { label: 'Respiratory Care', value: 'SNC009' },
  { label: 'Seizure Attendance', value: 'SNC012' },
  { label: 'Sign Language', value: 'SNC015' },
  { label: 'Stimming Attendance', value: 'SNC018' },
];

export const PAY_RATES = [
  { label: 'Any', value: '' },
  { label: '<$10-$10', value: '5-10' },
  { label: '$10-$15', value: '10-15' },
  { label: '$15-$20', value: '15-20' },
  { label: '$20-$25', value: '20-25' },
  { label: '$25-$30', value: '25-30' },
  { label: '$30-$35', value: '30-35' },
  { label: '$35-$40', value: '35-40' },
  { label: '$40-$45', value: '40-45' },
  { label: '$45-$50', value: '45-50' },
];

export const PAY_RATES_JOB = [
  { label: 'Any', value: '' },
  { label: '$45-$50', value: '45-50' },
  { label: '$40-$45', value: '40-45' },
  { label: '$35-$40', value: '35-40' },
  { label: '$30-$35', value: '30-35' },
  { label: '$25-$30', value: '25-30' },
  { label: '$20-$25', value: '20-25' },
  { label: '$15-$20', value: '15-20' },
  { label: '$10-$15', value: '10-15' },
  { label: '<$10-$10', value: '5-10' },
];

export const MILES = [
  { label: '1 mile', value: 1 },
  { label: '2 miles', value: 2 },
  { label: '3 miles', value: 3 },
  { label: '4 miles', value: 4 },
  { label: '5 miles', value: 5 },
  { label: '10 miles', value: 10 },
  { label: '15 miles', value: 15 },
  { label: '20 miles', value: 20 },
  { label: '25 miles', value: 25 },
  { label: '30 miles', value: 30 },
  { label: '35 miles', value: 35 },
  { label: '40 miles', value: 40 },
  { label: '45 miles', value: 45 },
  { label: '50 miles', value: 50 },
];

export const AVAILABILITIES = [
  { label: 'Any', value: '' },
  { label: 'Full-time', value: 'FULL_TIME' },
  { label: 'Part-time', value: 'PART_TIME' },
];

export const PROVIDER_SCHEDULES = [
  {
    label: 'Full-time (5 days/wk)',
    value: 'CHDSMBLAL001',
  },
  {
    label: 'Part-time (1-4 days/wk)',
    value: 'CHDSMBLAL002',
  },
  {
    label: 'Drop-In',
    value: 'CHDSMBLAL003',
  },
  {
    label: 'Half-Day (Morning)',
    value: 'CHDSMBLAL004',
  },
  {
    label: 'Half-Day (Afternoon)',
    value: 'CHDSMBLAL005',
  },
  {
    label: 'Full-Day',
    value: 'CHDSMBLAL006',
  },
  {
    label: 'Extended Care (Before School)',
    value: 'CHDSMBLAL007',
  },
  {
    label: 'Extended Care (After School)',
    value: 'CHDSMBLAL008',
  },
];

export const PROVIDER_AGES = [
  {
    label: 'Up to 6 months',
    value: 'CHDSMBLAG001',
  },
  {
    label: '7 months &ndash; 3 years',
    value: 'CHDSMBLAG002',
  },
  {
    label: '4 &ndash; 6 years',
    value: 'CHDSMBLAG003',
  },
  {
    label: '7 &ndash; 11 years',
    value: 'CHDSMBLAG004',
  },
  {
    label: '12+ years',
    value: 'CHDSMBLAG005',
  },
];

export const MORE_FILTERS_LIST = [
  { label: 'Non smoker', disabled: false, value: 'Non-smoker', key: 'nonSmoker' },
  { label: 'Has a car', value: 'Has a car', disabled: false, key: 'hasCar' },
  {
    label: 'Willing to care for sick children',
    value: 'Willing to care for sick children',
    disabled: true,
    key: 'willingtocareforsickchildren',
  },
  {
    label: 'Willing to have taxes withheld',
    value: 'Willing to have taxes withheld',
    disabled: true,
    key: 'willingtohavetaxwitheld',
  },
  { label: 'Has Photo', value: 'Has Photo', disabled: true, key: 'hasPhoto' },
  { label: 'New member', value: 'New member', disabled: true, key: 'newMember' },
  { label: 'Has Video', value: 'Has Video', disabled: true, key: 'hasVideo' },
  {
    label: 'Exclude teenage caregivers',
    value: 'Exclude teenage caregivers',
    disabled: true,
    key: 'excludeTeenageCaregivers',
  },
];

// Search filter END

export const CZEN_VISITOR_COOKIE_KEY = 'n_vis';
export const CZEN_SESSION_COOKIE_KEY = 'csc';

export enum SeoPageId {
  // TODO: will be removed after BE
  PROVIDER = 1050,
  JOB = 1061,
}

export enum ScreenName {
  PROVIDER = 'Provider Profile',
  SEGMENTATION = 'SEO Segmentation Page',
  JOB = 'Job Details',
  AI_BIO = 'What is an assisted bio modal',
  SCREEN = 'Screen',
}

export enum ScreenTemplate {
  PROVIDER = 'page.vw.seoProviderProfile',
  SEGMENTATION = 'page.vw.seoSearchListing',
  JOB = 'page.vw.seoJobDetail',
  COST_GUIDE = 'page.vw.costGuide',
  CAMPS_AND_ACTIVITIES = 'page.vw.campsAndActivities',
}

export enum TealiumSlot {
  VISITOR = '/us-subscription/segment/nonmember/',
}

export const FLOURISH_LEFT_IMAGE_URLS = {
  largeImageURL: '/img/cms/web/visitor/segmentation/flourish-left-dt.png',
  smallImageURL: '/img/cms/web/visitor/segmentation/flourish-left-mw.png',
} as const;

export enum LaunchDarklyEvaluationReasons {
  RULE_MATCH = 'RULE_MATCH',
  OVERRIDE = 'OVERRIDE',
  OFF = 'OFF',
}

export const FLORAL_WHEN_CARE_MODAL = {
  mwTopImageURL:
    'https://s.cdn-care.com/img/cms/web/visitor/segmentation/floral-when-care-modal-top.png',
  mwBottomImageURL:
    'https://s.cdn-care.com/img/cms/web/visitor/segmentation/floral-when-care-modal-bottom.png',
  dtLeftImageURL:
    'https://s.cdn-care.com/img/cms/web/visitor/segmentation/floral-when-care-modal-left.png',
  dtRightImageURL:
    'https://s.cdn-care.com/img/cms/web/visitor/segmentation/floral-when-care-modal-right.png',
} as const;

export const COST_GUIDE_HERO_BG_IMG_URL =
  'https://s.cdn-care.com/img/cms/web/visitor/segmentation/cost-guide-header-bg.png';

export enum DirectorySection {
  INVALID = 'INVALID',
  PROVIDERS = 'PROVIDERS',
  JOBS = 'JOBS',
  SEGMENTATION_STATE = 'SEGMENTATION_STATE',
  ALL_SERVICES_CITY = 'ALL_SERVICES_CITY',
}

export enum DirectorySubSections {
  INVALID = 'INVALID',
  CHILD_CARE = 'CHILD_CARE',
  BABYSITTERS = 'BABYSITTERS',
  NANNY = 'NANNY',
  TUTORS = 'TUTORS',
  HOUSE_KEEPING = 'HOUSE_KEEPING',
  PET_CARE = 'PET_CARE',
  SPECIAL_NEEDS = 'SPECIAL_NEEDS',
  SENIOR_CARE = 'SENIOR_CARE',
  CARE_GIGS = 'CARE_GIGS',
  DAY_CARE = 'DAY_CARE',
}

export const mapStateCodeToFullName = Object.freeze({
  [UsStateCode.AK]: 'Alaska',
  [UsStateCode.AL]: 'Alabama',
  [UsStateCode.AR]: 'Arkansas',
  [UsStateCode.AZ]: 'Arizona',
  [UsStateCode.CA]: 'California',
  [UsStateCode.CO]: 'Colorado',
  [UsStateCode.CT]: 'Connecticut',
  [UsStateCode.DC]: 'District of Columbia',
  [UsStateCode.DE]: 'Delaware',
  [UsStateCode.FE]: 'Federal',
  [UsStateCode.FL]: 'Florida',
  [UsStateCode.GA]: 'Georgia',
  [UsStateCode.HI]: 'Hawaii',
  [UsStateCode.IA]: 'Iowa',
  [UsStateCode.ID]: 'Idaho',
  [UsStateCode.IL]: 'Illinois',
  [UsStateCode.IN]: 'Indiana',
  [UsStateCode.KS]: 'Kansas',
  [UsStateCode.KY]: 'Kentucky',
  [UsStateCode.LA]: 'Louisiana',
  [UsStateCode.MA]: 'Massachusetts',
  [UsStateCode.MD]: 'Maryland',
  [UsStateCode.ME]: 'Maine',
  [UsStateCode.MI]: 'Michigan',
  [UsStateCode.MN]: 'Minnesota',
  [UsStateCode.MO]: 'Missouri',
  [UsStateCode.MS]: 'Mississippi',
  [UsStateCode.MT]: 'Montana',
  [UsStateCode.NC]: 'North Carolina',
  [UsStateCode.ND]: 'North Dakota',
  [UsStateCode.NE]: 'Nebraska',
  [UsStateCode.NH]: 'New Hampshire',
  [UsStateCode.NJ]: 'New Jersey',
  [UsStateCode.NM]: 'New Mexico',
  [UsStateCode.NV]: 'Nevada',
  [UsStateCode.NY]: 'New York',
  [UsStateCode.OH]: 'Ohio',
  [UsStateCode.OK]: 'Oklahoma',
  [UsStateCode.OR]: 'Oregon',
  [UsStateCode.PA]: 'Pennsylvania',
  [UsStateCode.PR]: 'Puerto Rico',
  [UsStateCode.RI]: 'Rhode Island',
  [UsStateCode.SC]: 'South Carolina',
  [UsStateCode.SD]: 'South Dakota',
  [UsStateCode.TN]: 'Tennessee',
  [UsStateCode.TX]: 'Texas',
  [UsStateCode.UT]: 'Utah',
  [UsStateCode.VA]: 'Virginia',
  [UsStateCode.VT]: 'Vermont',
  [UsStateCode.WA]: 'Washington',
  [UsStateCode.WI]: 'Wisconsin',
  [UsStateCode.WV]: 'West Virginia',
  [UsStateCode.WY]: 'Wyoming',
} as const);

export const mapServiceTypeToCzenServiceId = Object.freeze({
  [ServiceType.CHILD_CARE]: ServiceId.CHILDCARE,
  [ServiceType.SENIOR_CARE]: ServiceId.SENIRCARE,
  [ServiceType.HOUSEKEEPING]: ServiceId.HOUSEKEEP,
  [ServiceType.TUTORING]: ServiceId.TUTORINGX,
  [ServiceType.PET_CARE]: ServiceId.PETCAREXX,
  [ServiceType.CARE_GIGS]: ServiceId.CAREGIGSX,
  [ServiceType.SPECIAL_NEEDS]: ServiceId.SPCLNEEDS,
  [ServiceType.AU_PAIR]: null,
  [ServiceType.GENERIC]: null,
} as const);

export const mapServiceIdToMemberEnumToString = Object.freeze({
  [ServiceIdForMemberEnumType.ACADEMIC]: ServiceIdForMember.ACADEMIC,
  [ServiceIdForMemberEnumType.ART]: ServiceIdForMember.ART,
  [ServiceIdForMemberEnumType.BABYSITTER]: ServiceIdForMember.BABYSITTER,
  [ServiceIdForMemberEnumType.CAMPS_LOCATION]: ServiceIdForMember.CAMPS_LOCATION,
  [ServiceIdForMemberEnumType.CARE_GIGS]: ServiceIdForMember.CARE_GIGS,
  [ServiceIdForMemberEnumType.CARE_GIGS_JOB]: ServiceIdForMember.CARE_GIGS_JOB,
  [ServiceIdForMemberEnumType.CHILD_CARE]: ServiceIdForMember.CHILD_CARE,
  [ServiceIdForMemberEnumType.CHILD_CARE_AFTER_SCHOOL_CARE]:
    ServiceIdForMember.CHILD_CARE_AFTER_SCHOOL_CARE,
  [ServiceIdForMemberEnumType.CHILD_CARE_JOB]: ServiceIdForMember.CHILD_CARE_JOB,
  [ServiceIdForMemberEnumType.CHILD_CARE_LOCATION]: ServiceIdForMember.CHILD_CARE_LOCATION,
  [ServiceIdForMemberEnumType.DANCE]: ServiceIdForMember.DANCE,
  [ServiceIdForMemberEnumType.FAMILY_CHILD_CARE]: ServiceIdForMember.FAMILY_CHILD_CARE,
  [ServiceIdForMemberEnumType.HOME_CARE_LOCATION]: ServiceIdForMember.HOME_CARE_LOCATION,
  [ServiceIdForMemberEnumType.HOUSEKEEPING]: ServiceIdForMember.HOUSEKEEPING,
  [ServiceIdForMemberEnumType.HOUSEKEEPING_JOB]: ServiceIdForMember.HOUSEKEEPING_JOB,
  [ServiceIdForMemberEnumType.HOUSING_LOCATION]: ServiceIdForMember.HOUSING_LOCATION,
  [ServiceIdForMemberEnumType.MUSIC]: ServiceIdForMember.MUSIC,
  [ServiceIdForMemberEnumType.NANNY]: ServiceIdForMember.NANNY,
  [ServiceIdForMemberEnumType.NANNY_SHARE]: ServiceIdForMember.NANNY_SHARE,
  [ServiceIdForMemberEnumType.OUTDOOR_AND_ADVENTURE]: ServiceIdForMember.OUTDOOR_AND_ADVENTURE,
  [ServiceIdForMemberEnumType.PET_CARE]: ServiceIdForMember.PET_CARE,
  [ServiceIdForMemberEnumType.PET_CARE_JOB]: ServiceIdForMember.PET_CARE_JOB,
  [ServiceIdForMemberEnumType.PET_DAY_CARE_LOCATION]: ServiceIdForMember.PET_DAY_CARE_LOCATION,
  [ServiceIdForMemberEnumType.PET_SITTING_WALKING]: ServiceIdForMember.PET_SITTING_WALKING,
  [ServiceIdForMemberEnumType.SENIOR_CARE]: ServiceIdForMember.SENIOR_CARE,
  [ServiceIdForMemberEnumType.SENIOR_CARE_JOB]: ServiceIdForMember.SENIOR_CARE_JOB,
  [ServiceIdForMemberEnumType.SPECIAL_NEEDS]: ServiceIdForMember.SPECIAL_NEEDS,
  [ServiceIdForMemberEnumType.SPECIAL_NEEDS_JOB]: ServiceIdForMember.SPECIAL_NEEDS_JOB,
  [ServiceIdForMemberEnumType.SPORTS]: ServiceIdForMember.SPORTS,
  [ServiceIdForMemberEnumType.STEM]: ServiceIdForMember.STEM,
  [ServiceIdForMemberEnumType.SUMMER_CHILD_CARE_JOB]: ServiceIdForMember.SUMMER_CHILD_CARE_JOB,
  [ServiceIdForMemberEnumType.TUTORING]: ServiceIdForMember.TUTORING,
  [ServiceIdForMemberEnumType.TUTORING_AFTER_SCHOOL_CARE]:
    ServiceIdForMember.TUTORING_AFTER_SCHOOL_CARE,
  [ServiceIdForMemberEnumType.TUTORING_JOB]: ServiceIdForMember.TUTORING_JOB,
} as const);

export const mapCostGuidePercentageRelativePositionToString = Object.freeze({
  [CostGuidePercentageRelativePosition.HIGHER]: 'higher',
  [CostGuidePercentageRelativePosition.LOWER]: 'lower',
} as const);

export const mapStringtoUsStateCode: { [key: string]: UsStateCode | undefined } = Object.freeze({
  AK: UsStateCode.AK,
  AL: UsStateCode.AL,
  AR: UsStateCode.AR,
  AZ: UsStateCode.AZ,
  CA: UsStateCode.CA,
  CO: UsStateCode.CO,
  CT: UsStateCode.CT,
  DC: UsStateCode.DC,
  DE: UsStateCode.DE,
  FE: UsStateCode.FE,
  FL: UsStateCode.FL,
  GA: UsStateCode.GA,
  HI: UsStateCode.HI,
  IA: UsStateCode.IA,
  ID: UsStateCode.ID,
  IL: UsStateCode.IL,
  IN: UsStateCode.IN,
  KS: UsStateCode.KS,
  KY: UsStateCode.KY,
  LA: UsStateCode.LA,
  MA: UsStateCode.MA,
  MD: UsStateCode.MD,
  ME: UsStateCode.ME,
  MI: UsStateCode.MI,
  MN: UsStateCode.MN,
  MO: UsStateCode.MO,
  MS: UsStateCode.MS,
  MT: UsStateCode.MT,
  NC: UsStateCode.NC,
  ND: UsStateCode.ND,
  NE: UsStateCode.NE,
  NH: UsStateCode.NH,
  NJ: UsStateCode.NJ,
  NM: UsStateCode.NM,
  NV: UsStateCode.NV,
  NY: UsStateCode.NY,
  OH: UsStateCode.OH,
  OK: UsStateCode.OK,
  OR: UsStateCode.OR,
  PA: UsStateCode.PA,
  PR: UsStateCode.PR,
  RI: UsStateCode.RI,
  SC: UsStateCode.SC,
  SD: UsStateCode.SD,
  TN: UsStateCode.TN,
  TX: UsStateCode.TX,
  UT: UsStateCode.UT,
  VA: UsStateCode.VA,
  VT: UsStateCode.VT,
  WA: UsStateCode.WA,
  WI: UsStateCode.WI,
  WV: UsStateCode.WV,
  WY: UsStateCode.WY,
});

export const SERVICE_SHORT_NAMES: { [key: string]: ReviewInfoCareType } = {
  cc: ReviewInfoCareType.CHILD_CARE,
  sc: ReviewInfoCareType.SENIOR_CARE,
  hk: ReviewInfoCareType.HOUSEKEEPING,
  tu: ReviewInfoCareType.TUTORING,
  pc: ReviewInfoCareType.PET_CARE,
  sn: ReviewInfoCareType.SPECIAL_NEEDS,
} as const;

export const COST_GUIDE_INTERNAL_LINKS = () => {
  return {
    childCare: [
      {
        label: 'Atlanta Child Care Cost',
        address: 'https://www.care.com/cost/child-care/atlanta-ga',
      },
      {
        label: 'Austin Child Care Cost',
        address: 'https://www.care.com/cost/child-care/austin-tx',
      },
      {
        label: 'Boston Child Care Cost',
        address: 'https://www.care.com/cost/child-care/boston-ma',
      },
      {
        label: 'Charlotte Child Care Cost',
        address: 'https://www.care.com/cost/child-care/charlotte-nc',
      },
      {
        label: 'Chicago Child Care Cost',
        address: 'https://www.care.com/cost/child-care/chicago-il',
      },
      {
        label: 'Dallas Child Care Cost',
        address: 'https://www.care.com/cost/child-care/dallas-tx',
      },
      {
        label: 'Denver Child Care Cost',
        address: 'https://www.care.com/cost/child-care/denver-co',
      },
      {
        label: 'Houston Child Care Cost',
        address: 'https://www.care.com/cost/child-care/houston-tx',
      },
      {
        label: 'Los Angeles Child Care Cost',
        address: 'https://www.care.com/cost/child-care/los-angeles-ca',
      },
      { label: 'Miami Child Care Cost', address: 'https://www.care.com/cost/child-care/miami-fl' },
      {
        label: 'Minneapolis Child Care Cost',
        address: 'https://www.care.com/cost/child-care/minneapolis-mn',
      },
      {
        label: 'New York Child Care Cost',
        address: 'https://www.care.com/cost/child-care/new-york-ny',
      },
      {
        label: 'Orlando Child Care Cost',
        address: 'https://www.care.com/cost/child-care/orlando-fl',
      },
      {
        label: 'Philadelphia Child Care Cost',
        address: 'https://www.care.com/cost/child-care/philadelphia-pa',
      },
      {
        label: 'Phoenix Child Care Cost',
        address: 'https://www.care.com/cost/child-care/phoenix-az',
      },
      {
        label: 'San Antonio Child Care Cost',
        address: 'https://www.care.com/cost/child-care/san-antonio-tx',
      },
      {
        label: 'San Diego Child Care Cost',
        address: 'https://www.care.com/cost/child-care/san-diego-ca',
      },
      {
        label: 'San Francisco Child Care Cost',
        address: 'https://www.care.com/cost/child-care/san-francisco-ca',
      },
      {
        label: 'Seattle Child Care Cost',
        address: 'https://www.care.com/cost/child-care/seattle-wa',
      },
      {
        label: 'Washington, DC Child Care Cost',
        address: 'https://www.care.com/cost/child-care/washington-dc',
      },
    ],
    babysitters: [
      {
        label: 'Atlanta Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/atlanta-ga',
      },
      {
        label: 'Austin Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/austin-tx',
      },
      {
        label: 'Boston Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/boston-ma',
      },
      {
        label: 'Charlotte Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/charlotte-nc',
      },
      {
        label: 'Chicago Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/chicago-il',
      },
      {
        label: 'Dallas Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/dallas-tx',
      },
      {
        label: 'Denver Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/denver-co',
      },
      {
        label: 'Houston Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/houston-tx',
      },
      {
        label: 'Los Angeles Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/los-angeles-ca',
      },
      {
        label: 'Miami Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/miami-fl',
      },
      {
        label: 'Minneapolis Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/minneapolis-mn',
      },
      {
        label: 'New York Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/new-york-ny',
      },
      {
        label: 'Orlando Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/orlando-fl',
      },
      {
        label: 'Philadelphia Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/philadelphia-pa',
      },
      {
        label: 'Phoenix Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/phoenix-az',
      },
      {
        label: 'San Antonio Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/san-antonio-tx',
      },
      {
        label: 'San Diego Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/san-diego-ca',
      },
      {
        label: 'San Francisco Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/san-francisco-ca',
      },
      {
        label: 'Seattle Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/seattle-wa',
      },
      {
        label: 'Washington, DC Babysitter Cost',
        address: 'https://www.care.com/cost/babysitters/washington-dc',
      },
    ],
    nannies: [
      {
        label: 'Atlanta Nanny Cost',
        address: 'https://www.care.com/cost/nannies/atlanta-ga',
      },
      {
        label: 'Austin Nanny Cost',
        address: 'https://www.care.com/cost/nannies/austin-tx',
      },
      {
        label: 'Boston Nanny Cost',
        address: 'https://www.care.com/cost/nannies/boston-ma',
      },
      {
        label: 'Charlotte Nanny Cost',
        address: 'https://www.care.com/cost/nannies/charlotte-nc',
      },
      {
        label: 'Chicago Nanny Cost',
        address: 'https://www.care.com/cost/nannies/chicago-il',
      },
      {
        label: 'Dallas Nanny Cost',
        address: 'https://www.care.com/cost/nannies/dallas-tx',
      },
      {
        label: 'Denver Nanny Cost',
        address: 'https://www.care.com/cost/nannies/denver-co',
      },
      {
        label: 'Houston Nanny Cost',
        address: 'https://www.care.com/cost/nannies/houston-tx',
      },
      {
        label: 'Los Angeles Nanny Cost',
        address: 'https://www.care.com/cost/nannies/los-angeles-ca',
      },
      { label: 'Miami Nanny Cost', address: 'https://www.care.com/cost/nannies/miami-fl' },
      {
        label: 'Minneapolis Nanny Cost',
        address: 'https://www.care.com/cost/nannies/minneapolis-mn',
      },
      {
        label: 'New York Nanny Cost',
        address: 'https://www.care.com/cost/nannies/new-york-ny',
      },
      {
        label: 'Orlando Nanny Cost',
        address: 'https://www.care.com/cost/nannies/orlando-fl',
      },
      {
        label: 'Philadelphia Nanny Cost',
        address: 'https://www.care.com/cost/nannies/philadelphia-pa',
      },
      {
        label: 'Phoenix Nanny Cost',
        address: 'https://www.care.com/cost/nannies/phoenix-az',
      },
      {
        label: 'San Antonio Nanny Cost',
        address: 'https://www.care.com/cost/nannies/san-antonio-tx',
      },
      {
        label: 'San Diego Nanny Cost',
        address: 'https://www.care.com/cost/nannies/san-diego-ca',
      },
      {
        label: 'San Francisco Nanny Cost',
        address: 'https://www.care.com/cost/nannies/san-francisco-ca',
      },
      {
        label: 'Seattle Nanny Cost',
        address: 'https://www.care.com/cost/nannies/seattle-wa',
      },
      {
        label: 'Washington, DC Nanny Cost',
        address: 'https://www.care.com/cost/nannies/washington-dc',
      },
    ],
    petCare: [
      {
        label: 'Atlanta Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/atlanta-ga',
      },
      {
        label: 'Austin Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/austin-tx',
      },
      {
        label: 'Boston Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/boston-ma',
      },
      {
        label: 'Charlotte Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/charlotte-nc',
      },
      {
        label: 'Chicago Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/chicago-il',
      },
      {
        label: 'Dallas Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/dallas-tx',
      },
      {
        label: 'Denver Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/denver-co',
      },
      {
        label: 'Houston Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/houston-tx',
      },
      {
        label: 'Los Angeles Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/los-angeles-ca',
      },
      { label: 'Miami Pet Care Cost', address: 'https://www.care.com/cost/pet-care/miami-fl' },
      {
        label: 'Minneapolis Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/minneapolis-mn',
      },
      {
        label: 'New York Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/new-york-ny',
      },
      {
        label: 'Orlando Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/orlando-fl',
      },
      {
        label: 'Philadelphia Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/philadelphia-pa',
      },
      {
        label: 'Phoenix Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/phoenix-az',
      },
      {
        label: 'San Antonio Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/san-antonio-tx',
      },
      {
        label: 'San Diego Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/san-diego-ca',
      },
      {
        label: 'San Francisco Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/san-francisco-ca',
      },
      {
        label: 'Seattle Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/seattle-wa',
      },
      {
        label: 'Washington, DC Pet Care Cost',
        address: 'https://www.care.com/cost/pet-care/washington-dc',
      },
    ],
    seniorCare: [
      {
        label: 'Atlanta Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/atlanta-ga',
      },
      {
        label: 'Austin Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/austin-tx',
      },
      {
        label: 'Boston Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/boston-ma',
      },
      {
        label: 'Charlotte Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/charlotte-nc',
      },
      {
        label: 'Chicago Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/chicago-il',
      },
      {
        label: 'Dallas Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/dallas-tx',
      },
      {
        label: 'Denver Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/denver-co',
      },
      {
        label: 'Houston Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/houston-tx',
      },
      {
        label: 'Los Angeles Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/los-angeles-ca',
      },
      {
        label: 'Miami Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/miami-fl',
      },
      {
        label: 'Minneapolis Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/minneapolis-mn',
      },
      {
        label: 'New York Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/new-york-ny',
      },
      {
        label: 'Orlando Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/orlando-fl',
      },
      {
        label: 'Philadelphia Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/philadelphia-pa',
      },
      {
        label: 'Phoenix Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/phoenix-az',
      },
      {
        label: 'San Antonio Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/san-antonio-tx',
      },
      {
        label: 'San Diego Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/san-diego-ca',
      },
      {
        label: 'San Francisco Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/san-francisco-ca',
      },
      {
        label: 'Seattle Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/seattle-wa',
      },
      {
        label: 'Washington, DC Senior Care Cost',
        address: 'https://www.care.com/cost/senior-care/washington-dc',
      },
    ],
    housekeeping: [
      {
        label: 'Atlanta Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/atlanta-ga',
      },
      {
        label: 'Austin Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/austin-tx',
      },
      {
        label: 'Boston Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/boston-ma',
      },
      {
        label: 'Charlotte Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/charlotte-nc',
      },
      {
        label: 'Chicago Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/chicago-il',
      },
      {
        label: 'Dallas Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/dallas-tx',
      },
      {
        label: 'Denver Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/denver-co',
      },
      {
        label: 'Houston Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/houston-tx',
      },
      {
        label: 'Los Angeles Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/los-angeles-ca',
      },
      {
        label: 'Miami Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/miami-fl',
      },
      {
        label: 'Minneapolis Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/minneapolis-mn',
      },
      {
        label: 'New York Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/new-york-ny',
      },
      {
        label: 'Orlando Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/orlando-fl',
      },
      {
        label: 'Philadelphia Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/philadelphia-pa',
      },
      {
        label: 'Phoenix Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/phoenix-az',
      },
      {
        label: 'San Antonio Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/san-antonio-tx',
      },
      {
        label: 'San Diego Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/san-diego-ca',
      },
      {
        label: 'San Francisco Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/san-francisco-ca',
      },
      {
        label: 'Seattle Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/seattle-wa',
      },
      {
        label: 'Washington, DC Housekeeping Cost',
        address: 'https://www.care.com/cost/housekeeping/washington-dc',
      },
    ],
    tutors: [
      {
        label: 'Atlanta Tutor Cost',
        address: 'https://www.care.com/cost/tutors/atlanta-ga',
      },
      {
        label: 'Austin Tutor Cost',
        address: 'https://www.care.com/cost/tutors/austin-tx',
      },
      {
        label: 'Boston Tutor Cost',
        address: 'https://www.care.com/cost/tutors/boston-ma',
      },
      {
        label: 'Charlotte Tutor Cost',
        address: 'https://www.care.com/cost/tutors/charlotte-nc',
      },
      {
        label: 'Chicago Tutor Cost',
        address: 'https://www.care.com/cost/tutors/chicago-il',
      },
      {
        label: 'Dallas Tutor Cost',
        address: 'https://www.care.com/cost/tutors/dallas-tx',
      },
      {
        label: 'Denver Tutor Cost',
        address: 'https://www.care.com/cost/tutors/denver-co',
      },
      {
        label: 'Houston Tutor Cost',
        address: 'https://www.care.com/cost/tutors/houston-tx',
      },
      {
        label: 'Los Angeles Tutor Cost',
        address: 'https://www.care.com/cost/tutors/los-angeles-ca',
      },
      { label: 'Miami Tutor Cost', address: 'https://www.care.com/cost/tutors/miami-fl' },
      {
        label: 'Minneapolis Tutor Cost',
        address: 'https://www.care.com/cost/tutors/minneapolis-mn',
      },
      {
        label: 'New York Tutor Cost',
        address: 'https://www.care.com/cost/tutors/new-york-ny',
      },
      {
        label: 'Orlando Tutor Cost',
        address: 'https://www.care.com/cost/tutors/orlando-fl',
      },
      {
        label: 'Philadelphia Tutor Cost',
        address: 'https://www.care.com/cost/tutors/philadelphia-pa',
      },
      {
        label: 'Phoenix Tutor Cost',
        address: 'https://www.care.com/cost/tutors/phoenix-az',
      },
      {
        label: 'San Antonio Tutor Cost',
        address: 'https://www.care.com/cost/tutors/san-antonio-tx',
      },
      {
        label: 'San Diego Tutor Cost',
        address: 'https://www.care.com/cost/tutors/san-diego-ca',
      },
      {
        label: 'San Francisco Tutor Cost',
        address: 'https://www.care.com/cost/tutors/san-francisco-ca',
      },
      {
        label: 'Seattle Tutor Cost',
        address: 'https://www.care.com/cost/tutors/seattle-wa',
      },
      {
        label: 'Washington, DC Tutor Cost',
        address: 'https://www.care.com/cost/tutors/washington-dc',
      },
    ],
    personalAssistants: [
      {
        label: 'Atlanta Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/atlanta-ga',
      },
      {
        label: 'Austin Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/austin-tx',
      },
      {
        label: 'Boston Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/boston-ma',
      },
      {
        label: 'Charlotte Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/charlotte-nc',
      },
      {
        label: 'Chicago Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/chicago-il',
      },
      {
        label: 'Dallas Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/dallas-tx',
      },
      {
        label: 'Denver Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/denver-co',
      },
      {
        label: 'Houston Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/houston-tx',
      },
      {
        label: 'Los Angeles Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/los-angeles-ca',
      },
      {
        label: 'Miami Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/miami-fl',
      },
      {
        label: 'Minneapolis Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/minneapolis-mn',
      },
      {
        label: 'New York Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/new-york-ny',
      },
      {
        label: 'Orlando Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/orlando-fl',
      },
      {
        label: 'Philadelphia Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/philadelphia-pa',
      },
      {
        label: 'Phoenix Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/phoenix-az',
      },
      {
        label: 'San Antonio Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/san-antonio-tx',
      },
      {
        label: 'San Diego Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/san-diego-ca',
      },
      {
        label: 'San Francisco Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/san-francisco-ca',
      },
      {
        label: 'Seattle Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/seattle-wa',
      },
      {
        label: 'Washington, DC Personal Assistant Cost',
        address: 'https://www.care.com/cost/personal-assistants/washington-dc',
      },
    ],
  };
};

// array of items to be removed from the 'professional skills' list and moved to a seperate 'additional details' section in Qualifications
export const ADDITIONAL_DETAILS = [
  'Non-smoker',
  'Has a reliable car',
  'Comfortable with pets',
  'Provides equipment',
  'Provides supplies',
];

export const SERVICE_ID_FOR_JOB_DETAILS: { [key: string]: string } = {
  childcare: 'childcare',
  tutoring: 'tutoring',
  seniorcare: 'seniorcare',
  petcare: 'petcare',
  housekeeping: 'housekeeping',
};

export const SEO_SERVICE_MAP_SHORT_NAMES: { [key: string]: string } = {
  cc: `Child Care`,
  sc: `Senior Care`,
  hk: `Housekeeping`,
  tu: `Tutoring`,
  pc: `Pet Care`,
  cg: `Care Gigs`,
  sn: `Special Needs`,
} as const;

export const PROVIDER_SERVICES: Record<string, string> = {
  artClasses: 'art classes',
  bbqsOrPicnics: 'bbqs or picnics',
  birthdayParties: 'birthday parties',
  brainFitnessOrDakim: 'brain fitness or dakim',
  communitySponsoredActivities: 'community sponsored activities',
  continuingLearningPrograms: 'continuing learning programs',
  dances: 'dances',
  fitnessPrograms: 'fitness programs',
  gardeningClub: 'gardening club',
  holidayParties: 'holiday parties',
  intergenerationalPrograms: 'intergenerational programs',
  lightTherapyPrograms: 'light therapy programs',
  liveDanceOrTheater: 'live dance or theater',
  liveMusicalPerformances: 'live musical performances',
  petFocusPrograms: 'pet focus programs',
  plannedDayTrips: 'planned day trips',
  religiousServices: 'religious services',
  reminiscencePrograms: 'reminiscence programs',
  residentRunActivities: 'resident run activities',
  scheduledDailyActivities: 'scheduled daily activities',
  stretchingClasses: 'stretching classes',
  triviaGames: 'trivia games',
  wiiBowling: 'wii bowling',
  alsCare: 'ALS care',
  callSystemTwentyFourHours: '24-hour call system',
  dailyLivingAssistance: 'daily living assistance',
  diabetesCare: 'diabetes care',
  healthCareProviderCoordination: 'health care provider coordination',
  hospiceCare: 'hospice care',
  insulinInjections: 'insulin injections',
  mealPreparationAndService: 'meal preparation and service',
  medicationManagement: 'medication management',
  memoryCare: 'memory care',
  mentalWellnessCare: 'mental wellness care',
  mildCognitiveImpairmentCare: 'mild cognitive impairment care',
  nursingTwelveToSixteenHours: 'nursing 12-16 hours',
  nursingTwentyFourHours: 'nursing 24 hours',
  parkinsonsCare: 'parkinson’s care',
  physicalTherapy: 'physical therapy',
  preventativeHealthScreenings: 'preventative health screenings',
  rehabilitationProgram: 'rehabilitation program',
  respiteCare: 'respite care',
  strokeCare: 'stroke care',
  supervisionTwentyFourHours: '24-hour supervision',
  transportationArrangement: 'transportation arrangement',
  transportationArrangementMedical: 'medical transportation arrangement',
  personalCare: 'personal care',
  medicalPsychUnit: 'medical psych unit',
  skilledNursing: 'skilled nursing',
  barberOrBeautySalon: 'barber or beauty salon',
  cafeOrBistro: 'cafe or bistro',
  computerCenter: 'computer center',
  diningRoom: 'dining room',
  fitnessRoom: 'fitness room',
  gameRoom: 'game room',
  garden: 'garden',
  hour24Staff: '24 hour staff',
  laundryOrDryCleaning: 'laundry or dry cleaning',
  library: 'library',
  marketOnSite: 'market on-site',
  outdoorPatio: 'outdoor patio',
  outdoorSpace: 'outdoor space',
  privateDiningRooms: 'private dining rooms',
  religiousOrMeditationCenter: 'religious or meditation center',
  spaOrJacuzzi: 'spa or jacuzzi',
  swimmingPool: 'swimming pool',
  wellnessCenter: 'wellness center',
  allMealsProvided: 'all meals provided',
  anytimeDining: 'anytime dining',
  diabetic: 'diabetic dietary restrictions accommodated',
  glutenFree: 'gluten-free dietary restrictions accommodated',
  guestMeals: 'guests can eat at the center',
  halaal: 'halaal dietary restrictions accommodated',
  kosher: 'kosher dietary restrictions accommodated',
  lowOrNoSodium: 'low or no sodium dietary restrictions accommodated',
  noSugar: 'no sugar dietary restrictions accommodated',
  professionalChef: 'professional chef on-site',
  roomService: 'room service available',
  someMealsProvided: 'some meals provided',
  specialDietaryRestrictions: 'special dietary restrictions',
  vegan: 'vegan',
  vegetarian: 'vegetarian',
  beautician: 'beautician on-site',
  concierge: 'concierge',
  familyEducationAndSupport: 'family education and support',
  housekeeping: 'housekeeping services',
  laundryDoneIndividually: 'individual laundry',
  linen: 'linen',
  moveInCoordination: 'move-in coordination staff',
  cat: 'cats allowed',
  fish: 'fish allowed',
  dog: 'dogs allowed',
  airConditioning: 'air conditioning available',
  cable: 'cable available',
  fullyFurnished: 'fully furnished rooms available',
  groundFloorOrAccessibleUnits: 'ground floor or accessible units available',
  kitchenette: 'kitchenette available',
  privateBathroom: 'private bathroom available',
  telephone: 'telephone available',
  wheelchairAccessibleShower: 'wheelchair accessible shower available',
  wifi: 'Wi-Fi available',
  oneBedroom: 'one bedroom room',
  semiPrivateCompanionSuite: 'semi-private companion suite',
  studio: 'studio room',
  twoBedroom: 'two bedroom room',
};

type AgeRangeKey = '0 - 11' | '12 - 47' | '48 - 71' | '72 - 143' | '144 - 216';
export const FilterFieldNames = {
  categories: 'categories',
  subcategories: 'subcategories',
  dates: 'activityDates',
  ages: 'childCareAgeGroups',
  days: 'activityDaysOfWeek',
  activityType: 'activityParticipationType',
};

/** Ages */
export const AgeLabelToAgeRangeKeyMap: Record<string, AgeRangeKey> = {
  '0 - 11 mos': '0 - 11',
  '1 - 3 yrs': '12 - 47',
  '4 - 5 yrs': '48 - 71',
  '6 - 11 yrs': '72 - 143',
  '12+ yrs': '144 - 216',
};
export const ages = Object.keys(AgeLabelToAgeRangeKeyMap);

/** Activity type */
export const activityTypes = ['In-person', 'Online'];

/** Days */
export const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const DAYS_MAP_TO_FILTER: { [key: string]: string } = {
  Mon: DayOfWeek.MONDAY,
  Tue: DayOfWeek.TUESDAY,
  Wed: DayOfWeek.WEDNESDAY,
  Thu: DayOfWeek.THURSDAY,
  Fri: DayOfWeek.FRIDAY,
  Sat: DayOfWeek.SATURDAY,
  Sun: DayOfWeek.SUNDAY,
} as const;

export const AGES_MAP_TO_FILTER: { [key: string]: string } = {
  '0 - 11 mos': ChildCareAgeGroups.NEWBORN,
  '1 - 3 yrs': ChildCareAgeGroups.TODDLER,
  '4 - 5 yrs': ChildCareAgeGroups.EARLY_SCHOOL,
  '6 - 11 yrs': ChildCareAgeGroups.ELEMENTARY_SCHOOL,
  '12+ yrs': ChildCareAgeGroups.TEEN,
};

export const ACTIVITY_MAP_TO_FILTER: { [key: string]: string } = {
  'In-person': ActivityParticipationType.IN_PERSON,
  Online: ActivityParticipationType.ONLINE,
} as const;

export type ServiceMapShortName = keyof typeof SEO_SERVICE_MAP_SHORT_NAMES;

export const SEO_PAGE_ID = 1426;

export const ACTIVITY_CATEGORY_TITLES = {
  STEM: 'STEM',
  ART: 'Art',
  MUSIC: 'Music',
  SPORTS: 'Sports',
  DANCE: 'Dance',
  ACADEMIC: 'Academic',
  OUTDOOR_AND_ADVENTURE: 'Outdoor and Adventure',
} as const;

export type ActivityCategory = keyof typeof ACTIVITY_CATEGORY_TITLES;

export const ACTIVITY_CATEGORY_TO_KEY: Record<string, ActivityCategory> = {
  stem: 'STEM',
  art: 'ART',
  music: 'MUSIC',
  sports: 'SPORTS',
  dance: 'DANCE',
  academic: 'ACADEMIC',
  outdoor_and_adventure: 'OUTDOOR_AND_ADVENTURE',
};

interface ActivitySubcategoryMetadata {
  name: string;
  keyword: string;
  slug: string;
}

interface ActivityCategoryMeta {
  name: string;
  keyword: string;
  slug: string;
  subcategories: ActivitySubcategoryMetadata[];
}

export type ActivityCategoryMetadata = Record<ActivityCategory, ActivityCategoryMeta>;

export const ACTIVITY_CATEGORY_METADATA: ActivityCategoryMetadata = {
  MUSIC: {
    name: 'music',
    keyword: 'music',
    slug: '/explore/d/music',
    subcategories: [
      {
        name: 'band',
        keyword: 'band',
        slug: '/explore/d/band',
      },
      {
        name: 'drums',
        keyword: 'drums',
        slug: '/explore/d/drums',
      },
      {
        name: 'choir',
        keyword: 'choir',
        slug: '/explore/d/choir',
      },
      {
        name: 'voice',
        keyword: 'voice',
        slug: '/explore/d/voice',
      },
      {
        name: 'songwriting',
        keyword: 'songwriting',
        slug: '/explore/d/songwriting',
      },
      {
        name: 'music production',
        keyword: 'music production',
        slug: '/explore/d/music-production',
      },
      {
        name: 'violin',
        keyword: 'violin',
        slug: '/explore/d/violin',
      },
      {
        name: 'piano',
        keyword: 'piano',
        slug: '/explore/d/piano',
      },
      {
        name: 'theater',
        keyword: 'theater',
        slug: '/explore/d/theater',
      },
      {
        name: 'instrumental',
        keyword: 'instrumental',
        slug: '/explore/d/instrumental',
      },
      {
        name: 'dj skills',
        keyword: 'dj skills',
        slug: '/explore/d/dj-skills',
      },
      {
        name: 'guitar',
        keyword: 'guitar',
        slug: '/explore/d/guitar',
      },
    ],
  },
  DANCE: {
    name: 'dance',
    keyword: 'dance',
    slug: '/explore/d/dance',
    subcategories: [
      {
        name: 'break dance',
        keyword: 'break dance',
        slug: '/explore/d/break-dance',
      },
      {
        name: 'folklorico',
        keyword: 'folklorico',
        slug: '/explore/d/folklorico',
      },
      {
        name: 'hip hop',
        keyword: 'hip hop',
        slug: '/explore/d/hip-hop',
      },
      {
        name: 'jazz',
        keyword: 'jazz',
        slug: '/explore/d/jazz',
      },
      {
        name: 'ballet',
        keyword: 'ballet',
        slug: '/explore/d/ballet',
      },
      {
        name: 'tap dance',
        keyword: 'tap dance',
        slug: '/explore/d/tap-dance',
      },
    ],
  },
  ACADEMIC: {
    name: 'academic',
    keyword: 'academic',
    slug: '/explore/d/academic',
    subcategories: [
      {
        name: 'reading',
        keyword: 'reading',
        slug: '/explore/d/reading',
      },
      {
        name: 'french',
        keyword: 'french',
        slug: '/explore/d/french',
      },
      {
        name: 'writing',
        keyword: 'writing',
        slug: '/explore/d/writing',
      },
      {
        name: 'foreign languages',
        keyword: 'foreign languages',
        slug: '/explore/d/foreign-languages',
      },
      {
        name: 'spanish',
        keyword: 'spanish',
        slug: '/explore/d/spanish',
      },
      {
        name: 'mandarin',
        keyword: 'mandarin',
        slug: '/explore/d/mandarin',
      },
    ],
  },

  SPORTS: {
    name: 'sports',
    keyword: 'sports',
    slug: '/explore/d/sports',
    subcategories: [
      {
        name: 'gymnastics',
        keyword: 'gymnastics',
        slug: '/explore/d/gymnastics',
      },
      {
        name: 'cheerleading',
        keyword: 'cheerleading',
        slug: '/explore/d/cheerleading',
      },
      {
        name: 'track and field',
        keyword: 'track and field',
        slug: '/explore/d/track-and-field',
      },
      {
        name: 'soccer',
        keyword: 'soccer',
        slug: '/explore/d/soccer',
      },
      {
        name: 'tumbling',
        keyword: 'tumbling',
        slug: '/explore/d/tumbling',
      },
      {
        name: 'tennis',
        keyword: 'tennis',
        slug: '/explore/d/tennis',
      },
      {
        name: 'softball',
        keyword: 'softball',
        slug: '/explore/d/softball',
      },
      {
        name: 'swimming',
        keyword: 'swimming',
        slug: '/explore/d/swimming',
      },
      {
        name: 'volleyball',
        keyword: 'volleyball',
        slug: '/explore/d/volleyball',
      },
      {
        name: 'baseball',
        keyword: 'baseball',
        slug: '/explore/d/baseball',
      },
      {
        name: 'open gym',
        keyword: 'open gym',
        slug: '/explore/d/open-gym',
      },
      {
        name: 'basketball',
        keyword: 'basketball',
        slug: '/explore/d/basketball',
      },
      {
        name: 'martial arts',
        keyword: 'martial arts',
        slug: '/explore/d/martial-arts',
      },
    ],
  },
  OUTDOOR_AND_ADVENTURE: {
    name: 'outdoor and adventure',
    keyword: 'outdoor and adventure',
    slug: '/explore/d/outdoor-and-adventure',
    subcategories: [
      {
        name: 'horseback riding',
        keyword: 'horseback riding',
        slug: '/explore/d/horseback-riding',
      },
      {
        name: 'camping',
        keyword: 'camping',
        slug: '/explore/d/camping',
      },
      {
        name: 'hiking',
        keyword: 'hiking',
        slug: '/explore/d/hiking',
      },
      {
        name: 'fishing',
        keyword: 'fishing',
        slug: '/explore/d/fishing',
      },
      {
        name: 'rock climbing',
        keyword: 'rock climbing',
        slug: '/explore/d/rock-climbing',
      },
      {
        name: 'nature exploration',
        keyword: 'nature exploration',
        slug: '/explore/d/nature-exploration',
      },
      {
        name: 'archery',
        keyword: 'archery',
        slug: '/explore/d/archery',
      },
      {
        name: 'canoeing',
        keyword: 'canoeing',
        slug: '/explore/d/canoeing',
      },
      {
        name: 'survival skills',
        keyword: 'survival skills',
        slug: '/explore/d/survival-skills',
      },
    ],
  },
  STEM: {
    name: 'stem',
    keyword: 'stem',
    slug: '/explore/d/stem',

    subcategories: [
      {
        name: 'gaming',
        keyword: 'gaming',
        slug: '/explore/d/gaming',
      },
      {
        name: '3d printing',
        keyword: '3d printing',
        slug: '/explore/d/3d-printing',
      },
      {
        name: 'science',
        keyword: 'science',
        slug: '/explore/d/science',
      },
      {
        name: 'minecraft',
        keyword: 'minecraft',
        slug: '/explore/d/minecraft',
      },
      {
        name: 'robotics',
        keyword: 'robotics',
        slug: '/explore/d/robotics',
      },
      {
        name: 'astronomy',
        keyword: 'astronomy',
        slug: '/explore/d/astronomy',
      },
      {
        name: 'engineering',
        keyword: 'engineering',
        slug: '/explore/d/engineering',
      },
      {
        name: 'coding',
        keyword: 'coding',
        slug: '/explore/d/coding',
      },
      {
        name: 'biology',
        keyword: 'biology',
        slug: '/explore/d/biology',
      },
    ],
  },

  ART: {
    name: 'art',
    keyword: 'art',
    slug: '/explore/d/art',
    subcategories: [
      {
        name: 'creative play',
        keyword: 'creative play',
        slug: '/explore/d/creative-play',
      },
      {
        name: 'painting',
        keyword: 'painting',
        slug: '/explore/d/painting',
      },
      {
        name: 'ceramic',
        keyword: 'ceramic',
        slug: '/explore/d/ceramic',
      },
      {
        name: 'photography',
        keyword: 'photography',
        slug: '/explore/d/photography',
      },
      {
        name: 'craft',
        keyword: 'craft',
        slug: '/explore/d/craft',
      },
      {
        name: 'digital art',
        keyword: 'digital art',
        slug: '/explore/d/digital-art',
      },
      {
        name: 'drawing',
        keyword: 'drawing',
        slug: '/explore/d/drawing',
      },
    ],
  },
};

export const HTL_SLOTS = Object.freeze({
  SEO_DIRECTORY_INLINE_1: 'seo-directory-inline-1',
  SEO_DIRECTORY_INLINE_2: 'seo-directory-inline-2',
  SEO_DIRECTORY_INLINE_3: 'seo-directory-inline-3',
  SEO_DIRECTORY_INLINE_4: 'seo-directory-inline-4',
  SEO_DIRECTORY_RR_1: 'seo-directory-rr-1',
  SEO_JOB_PROFILE_INLINE_1: 'seo-job-profile-inline-1',
  SEO_JOB_PROFILE_INLINE_2: 'seo-job-profile-inline-2',
  SEO_PROV_PROFILE_RR_1: 'seo-prov-profile-rr-1',
  SEO_PROV_PROFILE_INLINE_1: 'seo-prov-profile-inline-1',
  SEO_PROV_PROFILE_INLINE_2: 'seo-prov-profile-inline-2',
  SEO_COST_INLINE_1: 'seo-cost-inline-1',
  SEO_COST_INLINE_2: 'seo-cost-inline-2',
} as const);

export const SC_SEEKER_IN_FACILITY_ROUTES = Object.freeze({
  WHO_NEEDS_CARE: 'in-facility/who-needs-care',
} as const);
